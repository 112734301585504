
export default function LandingPage() {
    return <>
        <h1>Wir Heiraten!</h1>
        <h3>Willkommen auf unserer Website</h3>
        <p>Zum Gästebereich mit weiteren Infos gehts hier lang:</p>
        <p className="guest-link" >
            <a href="https://gaestebereich.markwardt-family.de/">Zum Gästebereich</a>
        </p>
        <p>Zugangsdaten bekommt ihr von uns, schreibt uns einfach falls ihr sie noch nicht bekommen habt.</p>

        <p>Falls euch hier etwas fehlt meldet euch einfach bei uns 😉</p>
        <img width="100%" src={process.env.PUBLIC_URL + '/images/wir1.jpg'}/>
    </>
}
