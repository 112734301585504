export default function MorePage() {
    return <>
        <div className="more-content">
            <h1>Mehr</h1>
            <p>Mehr Infos gibt es hier mit Zugangsdaten:</p>
            <p className="guest-link" >
                <a href="https://gaestebereich.markwardt-family.de/">Zum Gästebereich</a>
            </p>
            <p>Zugangsdaten bekommt ihr von uns, schreibt uns einfach falls ihr sie noch nicht bekommen habt.</p>
            <div className="long-boi"></div>
            <p>Du kleiner Entdecker du... hier ist immer noch nix.</p>
        </div>
    </>
}
