import React from 'react';
import { NavLink } from 'react-router-dom';

export default function ListHeader() {
    return <>
        <ul className='list-header'>
            <li><NavLink to={'/'}>Home</NavLink></li>
            <li><NavLink to='/more'>Mehr...</NavLink></li>
            <li><a href='https://gaestebereich.markwardt-family.de/'>Zum Gästebereich</a></li>
        </ul>
    </>
}
