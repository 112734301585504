import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink, HashRouter} from 'react-router-dom';
import LandingPage from './pages/home/LandingPage';
import MorePage from './pages/test/MorePage';
import ListHeader from './objects/ListHeader';

function App() {
  return <HashRouter>
    <div className='full-page'>
      <ListHeader/>
      <div className='content'>
        <Routes>
          <Route key={'home'} path='/' element={<LandingPage/>} />
          <Route key={'more'} path='/more' element={<MorePage/>} />
        </Routes>
      </div>
    </div>
  </HashRouter>
}

export default App;
